import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  TextInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  downloadCSV,
} from "react-admin";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { tenantStatus } from "../../models/tenantStatus";
import Chip from "@material-ui/core/Chip";
import jsonExport from "jsonexport/dist";

const tenantFilters = [
  <TextInput
    source="namespace, banner_title_1, banner_content_1"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    style={{ width: 250 }}
    label="Namespace"
    source="namespace"
    alwaysOn
    resettable
  ></TextInput>,
];

const exporter = (records) => {
  const forExport = records.map((record) => {
    return {
      tenant_name: record.tenant.name,
      tenant_email: record.tenant.email,
      namespace: record.namespace,
    };
  });
  jsonExport(
    forExport,
    {
      headers: ["tenant_name", "tenant_email", "namespace"],
    },
    (err, csv) => {
      downloadCSV(csv, "ibe_sites");
    }
  );
};

const IbeSitesList = (props) => {
  return (
    <List
      filters={tenantFilters}
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
      <TextField source="tenant_group.name" label="ra.column.tenant_group_name" />
        <TextField source="tenant.name" label="ra.column.tenant_name" />
        <EmailField source="tenant.email" label="ra.column.tenant_email" />
        <TextField source="namespace" label="ra.column.namespace" />
        <EditButton style={{ float: "right" }} basePath="/ibe_sites" />
      </Datagrid>
    </List>
  );
};

export default IbeSitesList;
