import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

export const tenantGroupApi = {
getTenantGroup,
getTenantGroups
};

function getTenantGroup(tenant_group_id) {
	var gql = {
    query: `query MyQuery {
      tenant_groups(where: {id: {_eq: "${tenant_group_id}"}}) {
        name
        address_1
        address_2
        city
        country
        created_at
        email
        region
        phone_no
        status
        zip_code
        updated_at
        mapped_id
      }
    }   
    `,
  };

  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => { return data; });
}

function getTenantGroups(tenant_group_ids) {

  var whereQuery ="";
  var length = 0;
  tenant_group_ids.forEach(id => { 
    if(length == 0)
    {
       whereQuery = `${id}"`;
    }
    else if(length == tenant_group_ids.length - 1)
    whereQuery += `,"${id}`;
    else
    whereQuery += `,"${id}"`;

  length++
  });

	var gql = {
    query: `query MyQuery {
      tenant_groups(where: {_and: {mapped_id: {_in: ["${whereQuery}"]}}})
      {
        id
        name
        address_1
        address_2
        city
        country
        created_at
        email
        region
        phone_no
        status
        zip_code
        updated_at
        mapped_id
      }
    }   
    `,
  };

  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => { return data; });
}